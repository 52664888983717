<template>
  <div class="position-absolute d-flex flex-column align-items-center character-model">
    <div class="border border-dark z-index-5 w-50">
      <SocketProgressBar :skill="{id: `player-combat-${name}`}" style="height: 2px;"/>
      <div class="position-relative">
        <div class="health-bar w-100 h-100">
          <div class="progress-outer-bar bg-dark d-flex flex-row">
            <div class="flex-fill">
              <div class="progress-bar bg-dark-red-progress-grad border-0 flex-fill"
                   :style="{'width': healthPercent + '%'}">&nbsp;
              </div>
            </div>
            <div class="small text-white z-index-5 ms-auto text-end px-3"> {{
                character.health
              }}/{{ character.max_health }}
            </div>
          </div>
        </div>
      </div>
      <SocketProgressBar :skill="{id: `player-combat-${name}`}" style="height: 2px;"/>
    </div>
    <Player current-pose="combat" :equipment="equipment" :skin="skin"/>
    <HitSplatContainer :player-name="name"/>
  </div>
</template>
<script>
import Player from "@/components/player/Player";
import SocketProgressBar from "@/components/SocketProgressBar";
import HitSplatContainer from "@/components/combat/HitSplatContainer";

export default {
  name: "Friendly",
  components: {HitSplatContainer, SocketProgressBar, Player},
  props: {
    character: {
      type: Object
    },
    currentPose: {
      type: String,
      default: 'relaxed'
    },
    showPoseControls: {
      type: Boolean,
      default: false,
    },
    skin: {
      type: String,
      default: null,
    },
    equipment: {
      type: Object,
      required: false,
    },
    name: {
      type: String,
      default: null,
    }
  },
  computed: {
    healthPercent() {
      const calc = (100 / this.character.max_health) * this.character.health;
      return calc > 100 ? 100 : calc;
    }
  }
}
</script>