<template>
  <div ref="hitsplatContainer" class="hitsplat-container">
    <div v-for="(hit) in hits" :key="hit.hit_id"
         :class="{'bg-info': (hit.type && hit.type === 'miss') || (hit.damage === 0), 'bg-danger': hit.damage > 0}"
         class="hit-marker"
         :style="{
                    left: hit.left + 'px',
                    top: hit.top + 'px',
                    'z-index': (hit.hitId + 100)
                 }">
      {{ hit.damage }}
    </div>
  </div>
</template>
<style lang="scss" scoped>
.hitsplat-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
</style>
<script>
import {random} from "lodash/number";
export default {
  name: "HitSplatContainer",
  data() {
    return {
      id: 0,
      hits: [],
    }
  },
  props: {
    monsterId: {
      type: Number,
      default: null,
    },
    playerName: {
      type: String,
      default: null,
    }
  },
  sockets: {
    PLAYER_ATTACK(attack) {

      if (attack.enemy.id === this.monsterId) {
        this.id++;
        this.hits.push(Object.assign({
          hit_id: this.id,
          left: random(30, this.$refs.hitsplatContainer.clientWidth - 60),
          top: random(30, this.$refs.hitsplatContainer.clientHeight - 60),
        }, attack));

        setTimeout(() => {
          this.hits.splice(0, 1)
        }, 3000)
      }
    },
    MONSTER_ATTACK(attack) {

      if (attack.player === this.playerName) {
        this.id++;
        this.hits.push(Object.assign({
          hit_id: this.id,
          left: random(30, this.$refs.hitsplatContainer.clientWidth - 60),
          top: random(30, this.$refs.hitsplatContainer.clientHeight - 60),
        }, attack));

        setTimeout(() => this.hits.shift(), 2500)
      }
    }
  }
}
</script>