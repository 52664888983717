<template>
  <div class="monster-container" ref="monster"
       :class="{'monster-dead' : monster.health <= 0, 'monster-active': monster.active && monster.health > 0}">
    <div class="w-50">{{ monster.name }}</div>
    <div class="border border-dark z-index-5 monster-progress-bar-container w-50 mx-auto">
      <SocketProgressBar :skill="{id: `monster-combat-${monster.id}`}" style="height: 2px;"/>
      <div class="position-relative">
        <div class="health-bar w-100 h-100">
          <div class="progress-outer-bar bg-dark d-flex flex-row">
            <div class="flex-fill">
              <div class="progress-bar bg-dark-red-progress-grad border-0 flex-fill"
                   :style="{'width': healthWidth + '%'}">&nbsp;
              </div>
            </div>
            <div class="small text-white z-index-5 ms-auto text-end px-2"> {{ health }} / {{ monster.max_health }}
            </div>
          </div>
        </div>
      </div>
      <SocketProgressBar :skill="{id: `monster-combat-${monster.id}`}" style="height: 2px;"/>
    </div>
    <img :src="getModel" class="mw-100" :style="`--scale: ${monster.monster.scale || 1}`" :alt="monster.name"/>
    <HitSplatContainer :monster-id="monster.id"/>
  </div>
</template>
<script>

import SocketProgressBar from "@/components/SocketProgressBar";
import HitSplatContainer from "@/components/combat/HitSplatContainer";

export default {
  name: 'Monster',
  components: {HitSplatContainer, SocketProgressBar},
  props: {
    monster: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      hits: [],
      hitId: 0,
    }
  },
  watch: {
    'monster.health'(o) {
      if (o <= 0) {
        setTimeout(() => this.hits = [], 2000)
      }
    }
  },
  methods: {
    getOffsets(hit) {

      return {
        left: hit.left + 'px',
        top: hit.top + 'px',
        'z-index': (hit.hitId + 100),
      }
    }
  },
  mounted() {

  },
  computed: {
    getTexture() {
      return this.monster.monster.render.texture
    },
    getModel() {
      return `/img/mobs/cropped/${this.monster.monster.render.model}.png`
    },
    health() {
      return this.monster.health < 0 ? 0 : this.monster.health;
    },
    healthWidth() {
      if (this.monster.health <= 0)
        return 0;

      return (100 / this.monster.max_health) * this.monster.health
    }
  },
}
</script>
