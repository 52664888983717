<template>
  <div class="position-relative h-100">
    <div class="grid-combat-lobby" v-if="!inCombat">
      <div class="modal bg-dark bg-opacity-75" :class="{'show fade d-block': this.showUpgradeDialogue}" tabindex="-1"
           @click="closeUpgradeOption">
        <div class="modal-dialog bg-dark" @click.stop>
          <div class="modal-content bg-dark border-dark">
            <div class="modal-header border-0">
              <h5 class="modal-title text-white">Upgrade your combat inventory space</h5>
              <button type="button" class="btn-close text-white bg-white" data-bs-dismiss="modal"
                      aria-label="Close"
                      @click="closeUpgradeOption"></button>
            </div>
            <div class="modal-body d-flex flex-column gap-2">
              <h3 class="text-white-50" v-if="combatInventoryLevel <= 30">Resource cost for <strong>level {{ combatInventoryLevel }}</strong></h3>
              <h3 class="text-white-50" v-else>You are maxed</h3>
              <div class="d-flex flex-row align-items-center text-white-50" v-if="combatInventoryLevel <= 30">
                <img src="/img/sprites/coin.png" /> {{ formattedCostForUpgrade(combatInventoryLevel) }}
              </div>
              <button class="btn btn-primary btn-bg-block btn-warning py-2 text-black-50" :disabled="!hasEnoughTotalItems" @click="upgrade" v-if="combatInventoryLevel <= 30">Upgrade</button>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid zones">
        <div class="row">
          <div class="col-12 col-md-6" v-for="(skill, index) in skills" :key="index">
            <ZoneBox :skill="skill"/>
          </div>
        </div>
      </div>
      <div class="inventory dialogue-box has-header justify-content-center d-flex">
        <div class="dialogue-box-header">Combat Inventory</div>
        <div class="dialogue-box-inner d-flex flex-column">
          <Inventory :combat="true" class="dialogue-box-inner px-4" :hide-filter="true"/>
          <div class="mt-auto z-index-100 mb-4 d-flex flex-row align-items-center justify-content-around">
            <div>
              {{ spacesFilled }} / {{ combatInventorySize }}
            </div>
            <div>
              <button class="btn btn-primary btn-bg-block btn-warning py-2 text-black-50" @click="showUpgradeOption"><img src="/img/sprites/coin.png" />Upgrade</button>
            </div>
          </div>
        </div>
        <div class="dialogue-bg"></div>
      </div>

    </div>
    <div v-if="stage === 'waiting for fight' || inCombat" class="h-100 d-flex justify-content-center">
      <div class="position-absolute w-100 z-index-10">
        <div class="d-flex justify-content-between">
          <button @click="inventoryClosed = !inventoryClosed" class="btn btn-primary">
            <img src="/img/items/Inventory.png"/> Bag
          </button>
          <button @click="leaveCombat" class="btn btn-danger d-flex justify-content-center align-items-center">
            <img src="/img/icons/combat.png"/>
            Leave Combat
          </button>

        </div>

        <div class="inventory dialogue-box has-header justify-content-center d-flex"
             :class="{'d-none': inventoryClosed}" id="combat-inventory">
          <div class="dialogue-box-header">Combat Inventory</div>
          <Inventory :combat="true" class="dialogue-box-inner px-4"/>
          <div class="dialogue-bg"></div>
        </div>
      </div>


      <div class="combat-screen combat-bg position-relative"
           :style="[`--bg-image: url('${currentSkill?.background}')`]">
        <div class="friendly-container">
          <Friendly :character="friendly.character" :equipment="friendly.inventory" :skin="friendly.character.skin"
                    :name="friendly.character.username" v-for="(friendly, index) in friendlyTeam" :key="index"
                    :class="{'self': isSelf(friendly.character.username)}"/>
        </div>
        <div class="enemies d-flex justify-content-center align-items-center"
             :class="{'': stage === 'waiting for fight'}">
          <div v-if="stage === 'waiting for fight'">
            <h3 class="text-white btn-outline-dark">Searching for monsters...</h3>
          </div>
          <div v-else>
            <Monster :monster="enemy" v-for="(enemy, index) in enemies" :key="index"
                     class="model-render"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">

</style>
<script>
import ZoneBox from "@/components/ZoneBox";
import {mapGetters} from "vuex";
import Friendly from "@/components/combat/Friendly";
import Monster from "@/components/combat/Monster";
// import {random} from 'lodash/number'
import Inventory from "@/components/Inventory";
import upgradeFromGuestDialogue from "@/components/UpgradeFromGuestDialogue.vue";

export default {
  name: 'Combat',
  components: {Inventory, Monster, Friendly, ZoneBox},
  data() {
    return {
      inventoryClosed: true,
      showUpgradeDialogue: false,
      cost: 0,
    }
  },
  mounted() {

    // setTimeout(() => {
    //
    //   const mobContainer = this.$refs.mobContainer?.children;
    //
    //
    //   if (!mobContainer)
    //     return;
    //
    //   let oldX = 0;
    //   let oldY = 0;
    //
    //   for (let mob in mobContainer) {
    //     const singleMob = mobContainer[mob];
    //
    //     if (!(singleMob instanceof HTMLElement))
    //       return;
    //
    //     let x = random(oldX, oldX + 2);
    //     const y = random(0, 5) + oldY - oldY;
    //
    //     oldX = x + 1;
    //     oldY = y + random(-5, 15);
    //
    //     singleMob.style.left = `${x}%`;
    //     singleMob.style.display = 'block';
    //
    //
    //     if (!singleMob.classList.contains('monster-dead'))
    //       singleMob.style.opacity = '100%';
    //     else
    //       singleMob.style.opacity = '30%';
    //
    //     singleMob.style.bottom = `${y}%`;
    //   }
    // }, 500);
  },
  computed: {
    upgradeFromGuestDialogue() {
      return upgradeFromGuestDialogue
    },
    ...mapGetters({
      friendlyTeam: "combat/friendlyTeam",
      enemies: "combat/enemies",
      inCombat: "combat/inCombat",
      stage: "combat/stage",
      username: "user/getCharacterName",
      currentSkill: "user/activeSkillZone",
      inventory: "user/inventory",
      character: "user/character"
    }),
    skills() {
      return this.$store.state.config.zones && this.$store.state.config.zones.filter(i => i.skill === 'combat')
    },
    friendlyCount() {
      return this.enemies.length
    },
    combatInventory() {
      return this.inventory.filter(i => !i.equipped)
        .filter(i => !i.refine)
        .filter(i => i.combat_inventory === true)
    },
    combatInventorySize() {
      return this.character.combat_inventory_size;
    },
    combatInventoryLevel() {
      return this.character.combat_inventory_size - 7;
    },
    spacesFilled() {
      return this.combatInventory.length;
    },
    spacesEmpty() {
      return this.combatInventorySize - this.combatInventory.length;
    },

    hasEnoughTotalItems() {
      return this.character.gold >= this.costForUpgrade(this.combatInventoryLevel)
    },
  },
  methods: {
    isSelf(username) {
      return username === this.username
    },
    leaveCombat() {
      this.$socket.emit('auth:stop skill')
    },
    showUpgradeOption() {
      this.showUpgradeDialogue = true;
    },
    closeUpgradeOption() {
      this.showUpgradeDialogue = false;
    },
    upgrade() {
      this.$socket.emit("auth:combat upgrade inventory")
    },
    costForUpgrade(level) {
      return Number(level * 1000 * (level * level * level / 2));
    },
    formattedCostForUpgrade(level) {
      return new Intl.NumberFormat().format(
        this.costForUpgrade(level)
      )
    }
  },
  sockets: {
    'ENTER_COMBAT'() {
      // setTimeout(() => {
      //
      //   const mobContainer = this.$refs.mobContainer?.children;
      //
      //
      //   if (!mobContainer)
      //     return;
      //
      //   let oldX = 0;
      //   let oldY = 0;
      //
      //   for (let mob in mobContainer) {
      //     const singleMob = mobContainer[mob];
      //
      //     if (!(singleMob instanceof HTMLElement))
      //       return;
      //
      //     let x = random(oldX, oldX + 2);
      //     const y = random(0, 5) + oldY - oldY;
      //
      //     oldX = x + 5;
      //     oldY = y + random(-5, 15);
      //
      //     singleMob.style.left = `${x}%`;
      //     singleMob.style.display = 'block';
      //
      //
      //
      //     if (!singleMob.classList.contains('monster-dead'))
      //       singleMob.style.opacity = '100%';
      //     else
      //       singleMob.style.opacity = '30%';
      //
      //     singleMob.style.bottom = `${y}%`;
      //   }
      // }, 500);
    }
  }
}
</script>
